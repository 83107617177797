<template>
  <v-card>
    <v-toolbar flat>
      <v-text-field
        dense
        :loading="processing"
        hide-details
        autofocus
        outlined
        filled
        :placeholder="$t('typeNamePhoneEmailToFindBookings')"
        v-model="query"
        @keydown.enter="searchBookings"
      />
    </v-toolbar>
    <div class="px-4 pb-4">
      <v-btn
        class="primary"
        block
        @click="searchBookings"
        :loading="processing"
        :disabled="processing"
      >
        Search
      </v-btn>
    </div>
    <v-card-text>
      <BookingListSimple
        v-if="results !== null"
        :bookings="results"
        :load-items-callback="searchBookings"
        @selectBooking="selectBooking"
        :show-search="false"
      />
    </v-card-text>
  </v-card>
</template>

<script>

import BookingListSimple from '@/components/booking/BookingListSimple'

export default {
	name: 'BookingSearch',
	data() {
		return {
			query: '',
			processing: false,
			results: null
		}
	},
	components: {
		BookingListSimple
	},
	methods: {
		selectBooking(booking) {
			this.$store.commit('updateGenericDialog', null)
			this.$store.commit('updateGenericDialog', {
				title: 'BookingCard',
				component: 'components/booking/AddBookingCard',
				props: {
					bookingObject: booking,
					bookingTime: booking.startsAtTime
				}
			})
		},
		queryChanged(value) {
			this.processing = true
			this.searchBookings()
		},
		searchBookings() {
			this.processing = true
			return this.$store.dispatch('searchBookings', this.query).then(bookings => {
				this.results = bookings
				return bookings
			}).catch(() => {

			}).finally(() => {
				this.processing = false
			})
		}
	}
}
</script>
